<template>
  <div class="app-page-toolbar">
    <el-tooltip :content="exportButtonTooltip" :disabled="!exportButtonTooltip">
      <span>
        <el-button
          :disabled="exportButtonDisabled"
          @click="doExport()"
          icon="el-icon-fa-file-excel-o"
        >
          <app-i18n code="common.export"></app-i18n>
        </el-button>
      </span>
    </el-tooltip>
    <el-tooltip :content="sendButtonTooltip" :disabled="!sendButtonTooltip">
      <span>
        <el-button
          :disabled="sendButtonDisabled"
          @click="doSend()"
          icon="el-icon-fa-envelope-o"
        >
          <app-i18n code="common.send"></app-i18n>
        </el-button>
      </span>
    </el-tooltip>
    <!-- <el-tooltip
      :content="sendButtonTooltip"
      :disabled="!sendButtonTooltip"
      v-if="hasPermissionToSend"
    >
      <span>
        <el-button
          size="small"
          :disabled="destroyButtonDisabled"
          @click="doDestroyAllSelected"
          icon="el-icon-fa-trash"
          type="primary"
        >
          <app-i18n code="common.destroy"></app-i18n>
        </el-button>
      </span>
    </el-tooltip> -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { AenderungPermissions } from '@/modules/aenderung/aenderung-permissions';
import { i18n } from '@/i18n';

export default {
  name: 'app-aenderung-list-toolbar',

  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      hasRows: 'aenderung/list/hasRows',
      loading: 'aenderung/list/loading',
      selectedRows: 'aenderung/list/selectedRows',
    }),
    hasPermissionToEdit() {
      return new AenderungPermissions(this.currentUser).edit;
    },
    exportButtonDisabled() {
      return !this.hasRows || this.loading || this.exportLoading;
    },
    exportButtonTooltip() {
      return !this.hasRows ? i18n('common.noDataToExport') : null;
    },
    sendButtonDisabled() {
      return !this.selectedRows.length || this.loading;
    },
    sendButtonTooltip() {
      return !this.selectedRows.length ? i18n('common.mustSelectARow') : null;
    },
  },

  methods: {
    ...mapActions({
      doExport: 'aenderung/list/doExport',
      doSend: 'aenderung/list/doSend',
    }),

    async doDestroyAllSelected() {
      try {
        await this.$confirm(i18n('common.areYouSure'), i18n('common.confirm'), {
          confirmButtonText: i18n('common.yes'),
          cancelButtonText: i18n('common.no'),
          type: 'warning',
        });

        return this.doDestroyAll(this.selectedRows.map((item) => item.id));
      } catch (error) {
        // no
      }
    },
  },
};
</script>

<style></style>
