<template>
  <el-collapse value="Filter" accordion>
    <el-collapse-item title="Filter" name="Filter">
      <el-form
        :label-position="labelPosition"
        :label-width="labelWidthFilter"
        :model="model"
        :rules="rules"
        @submit.native.prevent="doFilter"
        class="filter"
        ref="form"
      >
        <el-row>
          <el-col :lg="8" :md="16" :sm="24">
            <el-form-item
              :label="fields.mitarbeiterNr.label"
              :prop="fields.mitarbeiterNr.name"
            >
              <el-input v-model="model[fields.mitarbeiterNr.name]" />
            </el-form-item>
          </el-col>
          <el-col :lg="8" :md="16" :sm="24">
            <el-form-item
              :label="fields.vollname.label"
              :prop="fields.vollname.name"
            >
              <el-input v-model="model[fields.vollname.name]" />
            </el-form-item>
          </el-col>
          <el-col :lg="8" :md="16" :sm="24">
            <el-form-item
              :label="fields.status.label"
              :prop="fields.status.name"
            >
              <el-select v-model="model[fields.status.name]">
                <el-option :value="undefined">--</el-option>
                <el-option
                  :key="option.id"
                  :label="option.label"
                  :value="option.id"
                  v-for="option in fields.status.options"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <div class="filter-buttons">
          <el-button
            size="small"
            :disabled="loading"
            @click="doFilter"
            icon="el-icon-fa-search"
            type="primary"
          >
            <app-i18n code="common.search"></app-i18n>
          </el-button>

          <el-button
            size="small"
            :disabled="loading"
            @click="doResetFilter"
            icon="el-icon-fa-undo"
          >
            <app-i18n code="common.reset"></app-i18n>
          </el-button>
        </div>
      </el-form>
    </el-collapse-item>
  </el-collapse>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { FilterSchema } from '@/shared/form/filter-schema';
import { AenderungModel } from '@/modules/aenderung/aenderung-model';

const { fields } = AenderungModel;

const filterSchema = new FilterSchema([
  fields.id,
  fields.mitarbeiterNr,
  fields.vollname,
  fields.status,
]);

export default {
  name: 'app-aenderung-list-filter',

  data() {
    return {
      rules: filterSchema.rules(),
      model: {},
    };
  },

  async mounted() {
    this.model = filterSchema.initialValues(this.filter, this.$route.query);
    this.setDefaultStatus();
    return this.doFilter();
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthFilter: 'layout/labelWidthFilter',
      elementUiDateFormat: 'layout/elementUiDateFormat',
      loading: 'aenderung/list/loading',
      filter: 'aenderung/list/filter',
    }),

    fields() {
      return fields;
    },
  },

  methods: {
    ...mapActions({
      doReset: 'aenderung/list/doReset',
      doFetch: 'aenderung/list/doFetch',
    }),

    setDefaultStatus() {
      this.model.status = 'nichtGesendet';
    },

    async doResetFilter() {
      this.model = filterSchema.initialValues();
      this.setDefaultStatus();
      this.$refs.form.clearValidate();
      return this.doReset();
    },

    async doFilter() {
      try {
        await this.$refs.form.validate();
        this.$refs.form.clearValidate();
      } catch (error) {
        return;
      }

      const filter = filterSchema.cast(this.model);
      return this.doFetch({
        filter,
      });
    },
  },
};
</script>

<style></style>
