aenderung
<template>
  <div>
    <el-table
      :border="true"
      :data="rows"
      @sort-change="doChangeSort"
      ref="table"
      row-key="id"
      v-loading="loading"
    >
      <el-table-column
        type="selection"
        width="55"
        :selectable="selectable"
      ></el-table-column>

      <el-table-column
        :label="fields.mitarbeiterNr.label"
        :prop="fields.mitarbeiterNr.name"
        sortable="true"
      >
        <template v-slot="scope">
          <strong>
            {{ presenter(scope.row, 'mitarbeiterNr') }}
          </strong>
        </template>
      </el-table-column>

      <el-table-column
        :label="fields.vollname.label"
        :prop="fields.vollname.name"
      >
        <template v-slot="scope">
          <strong>{{ presenter(scope.row, 'vollname') }}</strong>
        </template>
      </el-table-column>

      <el-table-column
        :label="fields.mandant.label"
        :prop="fields.mandant.name"
      >
        <template v-slot="scope">
          <app-list-item-relation-to-one
            :label="fields.mandant.label"
            :readPermission="fields.mandant.readPermission"
            :navigatePermission="false"
            :value="presenter(scope.row, 'mandant')"
          ></app-list-item-relation-to-one>
        </template>
      </el-table-column>
      <el-table-column
        :label="fields.kostenstelle.label"
        :prop="fields.kostenstelle.name"
      >
        <template v-slot="scope">
          <app-list-item-relation-to-many
            :label="fields.kostenstelle.label"
            :readPermission="fields.kostenstelle.readPermission"
            :navigatePermission="false"
            :value="presenter(scope.row, 'kostenstelle')"
          ></app-list-item-relation-to-many>
        </template>
      </el-table-column>
      <el-table-column
        :label="fields.verwendungKostenstelle.label"
        :prop="fields.verwendungKostenstelle.name"
      >
        <template v-slot="scope">
          <app-list-item-relation-to-many
            :label="fields.verwendungKostenstelle.label"
            :readPermission="fields.verwendungKostenstelle.readPermission"
            :navigatePermission="false"
            :value="presenter(scope.row, 'verwendungKostenstelle')"
          ></app-list-item-relation-to-many>
        </template>
      </el-table-column>

      <el-table-column
        :label="fields.grundgehaltGesamtbrutto.label"
        :prop="fields.grundgehaltGesamtbrutto.name"
      >
        <template v-slot="scope">
          <div style="text-align: right !important;">
            <strong>
              {{ presenter(scope.row, 'grundgehaltGesamtbrutto') }}
            </strong>
          </div>
        </template>
      </el-table-column>

      <el-table-column
        :label="fields.gesamtwochenstunden.label"
        :prop="fields.gesamtwochenstunden.name"
      >
        <template v-slot="scope">
          <strong>
            {{ presenter(scope.row, 'gesamtwochenstunden') }}
          </strong>
        </template>
      </el-table-column>

      <el-table-column
        :label="fields.aenderung.label"
        :prop="fields.aenderung.name"
      >
        <template v-slot="scope">
          <strong>
            {{ presenter(scope.row, 'aenderung') }}
          </strong>
        </template>
      </el-table-column>
    </el-table>

    <div class="el-pagination-wrapper">
      <el-pagination
        :current-page="pagination.currentPage || 1"
        :disabled="loading"
        :layout="paginationLayout"
        :total="count"
        @current-change="doChangePaginationCurrentPage"
        @size-change="doChangePaginationPageSize"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { AenderungModel } from '@/modules/aenderung/aenderung-model';
import { mapGetters, mapActions } from 'vuex';
import { AenderungPermissions } from '@/modules/aenderung/aenderung-permissions';
import { i18n } from '@/i18n';

const { fields } = AenderungModel;

export default {
  name: 'app-aenderung-list-table',

  mounted() {
    this.$watch(
      () => {
        return this.$refs.table.selection;
      },
      (rows) => {
        this.$store.dispatch('aenderung/list/doSetSelectedRows', rows);
      },
    );
  },

  computed: {
    ...mapGetters({
      rows: 'aenderung/list/rows',
      count: 'aenderung/list/count',
      loading: 'aenderung/list/loading',
      pagination: 'aenderung/list/pagination',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      destroyLoading: 'aenderung/destroy/loading',
      paginationLayout: 'layout/paginationLayout',
    }),

    hasPermissionToEdit() {
      return new AenderungPermissions(this.currentUser).edit;
    },

    hasPermissionToDestroy() {
      return new AenderungPermissions(this.currentUser).destroy;
    },

    fields() {
      return fields;
    },
  },

  methods: {
    ...mapActions({
      doChangeSort: 'aenderung/list/doChangeSort',
      doChangePaginationCurrentPage:
        'aenderung/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize: 'aenderung/list/doChangePaginationPageSize',
      doMountTable: 'aenderung/list/doMountTable',
      doDestroy: 'aenderung/destroy/doDestroy',
    }),

    presenter(row, fieldName) {
      return AenderungModel.presenter(row, fieldName);
    },

    selectable(row) {
      return row.status === 'nichtGesendet';
    },

    async doDestroyWithConfirm(id) {
      try {
        await this.$confirm(i18n('common.areYouSure'), i18n('common.confirm'), {
          confirmButtonText: i18n('common.yes'),
          cancelButtonText: i18n('common.no'),
          type: 'warning',
        });

        return this.doDestroy(id);
      } catch (error) {
        // no
      }
    },
  },
};
</script>

<style></style>
