import { i18n } from '@/i18n';
import IdField from '@/shared/fields/id-field';
import DateTimeRangeField from '@/shared/fields/date-time-range-field';
import DateTimeField from '@/shared/fields/date-time-field';
import DateField from '@/shared/fields/date-field';
import StringField from '@/shared/fields/string-field';
import DecimalField from '@/shared/fields/decimal-field';
import { KostenstelleField } from '@/modules/kostenstelle/kostenstelle-field';
import { VerwendungKostenstelleField } from '@/modules/verwendung-kostenstelle/verwendung-kostenstelle-field';
import { GenericModel } from '@/shared/model/generic-model';

import { MandantField } from '@/modules/mandant/mandant-field';

import MoneyField from '@/shared/fields/money-field';
import { FakultaetField } from '@/modules/fakultaet/fakultaet-field';
import EnumeratorField from '@/shared/fields/enumerator-field';

function label(name) {
  return i18n(`entities.aenderung.fields.${name}`);
}
function enumeratorLabel(name, value) {
  return i18n(`entities.aenderung.enumerators.${name}.${value}`);
}

const fields = {
  id: new IdField('id', label('id')),
  mitarbeiterNr: new StringField('mitarbeiterNr', label('mitarbeiterNr'), {
    required: false,
    max: 50,
  }),
  aenderung: new StringField('aenderung', label('aenderung'), {
    required: false,
    max: 50,
  }),
  vollname: new StringField('vollname', label('vollname')),
  svNummer: new StringField('svNummer', label('svNummer'), {
    required: true,
    matches: /^[0-9]{4}/,
    max: 20,
  }),
  grundgehaltGesamtbrutto: new MoneyField(
    'grundgehaltGesamtbrutto',
    label('grundgehaltGesamtbrutto'),
    {
      scale: 2,
      min: 0,
      max: 10000000,
      required: false,
    },
  ),
  gesamtwochenstunden: new DecimalField(
    'gesamtwochenstunden',
    label('gesamtwochenstunden'),
    {
      scale: 2,
      min: 0,
      max: 1000,
      required: false,
    },
  ),
  eintrittsdatum: new DateField('eintrittsdatum', label('eintrittsdatum')),
  austrittsdatum: new DateField('austrittsdatum', label('austrittsdatum')),
  mandant: MandantField.relationToOne('mandant', label('mandant'), {}),
  kostenstelle: KostenstelleField.relationToMany(
    'kostenstelle',
    label('kostenstelle'),
    {},
  ),
  fakultaet: FakultaetField.relationToMany('fakultaet', label('fakultaet'), {}),
  verwendungKostenstelle: VerwendungKostenstelleField.relationToMany(
    'verwendungKostenstelle',
    label('verwendungKostenstelle'),
    {},
  ),
  status: new EnumeratorField(
    'status',
    label('status'),
    [
      {
        id: 'gesendet',
        label: enumeratorLabel('status', 'gesendet'),
      },
      {
        id: 'nichtGesendet',
        label: enumeratorLabel('status', 'nichtGesendet'),
      },
    ],
    { required: true },
  ),
  createdAt: new DateTimeField('createdAt', label('createdAt')),
  updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
  updatedAtRange: new DateTimeRangeField(
    'updatedAtRange',
    label('updatedAtRange'),
  ),
};

export class AenderungModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
