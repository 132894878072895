<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <app-i18n code="entities.aenderung.menu"></app-i18n>
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div class="app-content-page">
      <h1 class="app-content-title">
        <app-i18n code="entities.aenderung.list.title"></app-i18n>
      </h1>

      <app-aenderung-list-toolbar></app-aenderung-list-toolbar>
      <app-aenderung-list-filter></app-aenderung-list-filter>
      <app-aenderung-list-table></app-aenderung-list-table>
    </div>
  </div>
</template>

<script>
import AenderungListFilter from '@/modules/aenderung/components/aenderung-list-filter.vue';
import AenderungListTable from '@/modules/aenderung/components/aenderung-list-table.vue';
import AenderungListToolbar from '@/modules/aenderung/components/aenderung-list-toolbar.vue';

export default {
  name: 'app-aenderung-list-page',
  components: {
    [AenderungListFilter.name]: AenderungListFilter,
    [AenderungListTable.name]: AenderungListTable,
    [AenderungListToolbar.name]: AenderungListToolbar,
  },
};
</script>

<style></style>
